import loadjs from 'loadjs'

/*
 *
 * Urgent polyfills (before first render)
 *
 */

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }
}

/*
 *
 * Non-urgent polyfills and global scripts (after first render)
 *
 */

export const onInitialClientRender = () => {
  // Fetch polyfill for FormNetlify (IE)
  if (typeof window.fetch === `undefined`) {
    loadjs(
      `https://cdnjs.cloudflare.com/ajax/libs/fetch/2.0.4/fetch.min.js`,
      `fetch`
    )
  }
}
