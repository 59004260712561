module.exports = [{
      plugin: require('/Users/baharehadham/Documents/Projects/rachel-krehm/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/Users/baharehadham/Documents/Projects/rachel-krehm/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/baharehadham/Documents/Projects/rachel-krehm/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
